const Utils = require('@igp/shared').Utils;
const store = require('../../../lib/StoreData');
const Session = require('../../../lib/Session');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
    this.allowedWithoutAuthentication = true;
    this.emitMessageOnInitiatorInstance = true;

    const pcgName = store.state.package.name;
    const pcgVersion = store.state.package.version;
    this.softwareSerial = `${pcgName.substring(pcgName.indexOf('/') + 1)}_v${pcgVersion}`;
  }

  request() {
    Utils.setLocalStorage('deviceId', Utils.getLocalStorage('deviceId') || Utils.generateUUID());
    const deviceId = Utils.getLocalStorage('deviceId');

    const clientType = store.state.initOptions.clientType;
    const data = {
      cageShortName: store.state.initOptions.cage,
      languageCode: store.state.languageCode,
      softwareSerial: this.softwareSerial,
      userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : '',
      loginSite: store.state.initOptions.loginSite,
      sessionId: Session.get() || '',
      ...(deviceId && { deviceId }),
      ...(clientType && { clientType }),
    }

    return super.request(data);
  }

  response(message) {
    return new Promise((resolve) => {
      Session.set(message.data.sessionId);
      super.response(message).then(resolve);
    });
  }

}
